<!-- ======= Top Bar ======= -->
<section id="topbar" class="d-flex align-items-center">
  <div class="container d-flex justify-content-center justify-content-md-between">
    <div class="contact-info d-flex align-items-center">
      <i class="bi bi-envelope d-flex align-items-center"><a
          href="mailto:contact@hamiltonrappold.com">contact@hamiltonrappold.com</a></i>
      <i class="bi bi-phone d-flex align-items-center ms-4"><span>614-370-8354</span></i>
    </div>

    <div class="cta d-none d-md-flex align-items-center">
      <a href="#about" class="scrollto">Learn More</a>
    </div>
  </div>
</section>

<!-- ======= Header ======= -->
<header id="header" class="d-flex align-items-center">
  <div class="container d-flex align-items-center justify-content-between">

    <div class="logo">
      <h1><a href="/"><img src="assets/img/logo-1.png" alt=""> <small>H&R INDUSTRIAL SERVICES</small></a></h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
    </div>

    <nav id="navbar" class="navbar">
      <ul>
        <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
        <li><a class="nav-link scrollto" href="#about">About</a></li>
        <li><a class="nav-link scrollto" href="#services">Services</a></li>
        <li><a class="nav-link scrollto" href="#team">Team</a></li>
        <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
      </ul>
      <!-- <i class="bi bi-list mobile-nav-toggle"></i> -->
    </nav><!-- .navbar -->

  </div>
</header><!-- End Header -->
<alert [isOpen]="formAlert.show" [dismissible]="true" [type]="'success'" style="position: fixed; z-index: 1000; top: 0px; width: 100%; z-index: 100;">
  <strong>Success!</strong> {{formAlert.title}} {{formAlert.text}}
</alert>

<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex flex-column justify-content-center align-items-center">
  <div class="container" data-aos="fade-in">
    <h1>H&R INDUSTRIAL SERVICES</h1>
    <h2>Reliable Professionals Providing High-Quality, Cost Effective Industrial Services</h2>
    <div class="d-flex align-items-center">
      <i class="bx bxs-right-arrow-alt get-started-icon"></i>
      <a href="#about" class="btn-get-started scrollto">Learn More</a>
    </div>
  </div>
</section><!-- End Hero -->

<main id="main">

  <!-- ======= Why Us Section ======= -->
  <section id="why-us" class="why-us">
    <div class="container">

      <div class="row">
        <div class="col-xl-4 col-lg-5" data-aos="fade-up">
          <div class="content">
            <h3>Why Choose H&R Industrial Services?</h3>
            <p>
              With the combination of exceptional customer service and a highly trained team, H&R Industrial Services will build
and service for your future.  From installing multiple types of racking systems, shelving systems, material handling equipment to
facility safety/security support equipment, H&R Industrial Services has successfully and efficiently completed projects all over the United States.
            </p>
            <div class="text-center">
              <a href="#services" class="more-btn">Learn More <i class="bx bx-chevron-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-lg-7 d-flex">
          <div class="icon-boxes d-flex flex-column justify-content-center">
            <div class="row">
              <div class="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                <div class="icon-box mt-4 mt-xl-0">
                  <i class="bx bx-wrench"></i>
                  <h4>Installation</h4>
                  <p>Multiple Types of Racking Systems, Shelving Systems, Material Handling Equipment & More</p>
                </div>
              </div>
              <div class="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                <div class="icon-box mt-4 mt-xl-0">
                  <i class="bx bx-reset"></i>
                  <h4>Takedown/Relocation</h4>
                  <p>We can internally support the transportation of materials from point A to point B.</p>
                </div>
              </div>
              <div class="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                <div class="icon-box mt-4 mt-xl-0">
                  <i class="bx bx-plus-medical"></i>
                  <h4>Safety/Security</h4>
                  <p>Safety Is A Priority, And We Offer Facility Safety/Security Support Equipment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Why Us Section -->

  <!-- ======= About Section ======= -->
  <section id="about" class="about section-bg">
    <div class="container">

      <div class="row">
        <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative"
          data-aos="fade-right">
          <!-- <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="glightbox play-btn mb-4"></a> -->
        </div>

        <div
          class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
          <h4 data-aos="fade-up">About us</h4>
          <h3 data-aos="fade-up">H&R Industrial Services takes pride in every completed job.</h3>
          <p data-aos="fade-up">H&R Industrial Services was founded and built by two guys who saw an opportunity
            to provide quality service and knowledge to every type of mechanical and industrial project.
            As H&R Industrial Services continues to grow and maintain an excellent service rating for project completion and safety,
            we are looking forward to a bright future! </p>

          <div class="icon-box" data-aos="fade-up">
            <div class="icon"><i class="bx bx-clipboard"></i></div>
            <h4 class="title"><a href="">Safety Skills Training</a></h4>
            <p class="description">Our highly trained team members participate in safety training every year</p>
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="bx bx-list-check"></i></div>
            <h4 class="title"><a href="">OSHA Compliant</a></h4>
            <p class="description">We meet all OSHA standards and regulations. The safety and health of our team members is a priority.</p>
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="bx bi-check-lg"></i></div>
            <h4 class="title"><a href="">Accident Compliant</a></h4>
            <p class="description">Because of our high safety requirements, we are proud to state that we have never had an on site accident. </p>
          </div>

        </div>
      </div>

    </div>
  </section><!-- End About Section -->

  <!-- ======= Services Section ======= -->
  <section id="services" class="services section-bg">
    <div class="container">

      <div class="section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>H&R Industrial Services multi-specialty trained installers have completed projects that include, but are not limited to:</p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6" data-aos="fade-up">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-arrow-bar-up"></i></div>
            <h4 class="title"><a href="">Structural Rack</a></h4>
            <p class="description">High-quality, heavy-channel steel that can store heavy pallets and withstand harsh conditions, such as lift truck impact.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-truck"></i></div>
            <h4 class="title"><a href="">Drive-in Rack</a></h4>
            <p class="description">A cost-effective, high density storage system
              that allows storage of pallets multiple positions deep, reducing the space required for aisles.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-sort-up"></i></div>
            <h4 class="title"><a href="">Selective Racking</a></h4>
            <p class="description">One pallet deep and has a maximum of two racks that are placed one after the other.
              It follows a First in First Out (FIFO) racking system.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-arrow-bar-left"></i></div>
            <h4 class="title"><a href="">Pushback Racking</a></h4>
            <p class="description">Pallet storage method that allows pallets to be stored from 2 to 6 deep on either side of an aisle,
              giving you higher storage density than other forms of racking.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-water"></i></div>
            <h4 class="title"><a href="">Flow Rack</a></h4>
            <p class="description">Helps with the flow of materials: material is introduced on one side and flows through the unit to the other side. </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-chevron-bar-left"></i></div>
            <h4 class="title"><a href="">Pallet Flow Rack</a></h4>
            <p class="description">Pallets are loaded at the back end of the rack system and gently “flow” down to the front of the lane at the opposite end.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-arrows-expand"></i></div>
            <h4 class="title"><a href="">Span Track for Pallet Racking</a></h4>
            <p class="description">A structure that fits into pallet rack making it easy to convert from static to dynamic storage.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-stoplights"></i></div>
            <h4 class="title"><a href="">Guard Rail</a></h4>
            <p class="description">Provides a physical and visual barrier to protect from forklift and powered vehicle accidents.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-arrow-right-square"></i></div>
            <h4 class="title"><a href="">Guide Rail</a></h4>
            <p class="description">Propels products of various sizes, shapes, and dimensions through the factory over the course of their assembly.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-layout-wtf"></i></div>
            <h4 class="title"><a href="">Cantilever Rack</a></h4>
            <p class="description">A storage system designed to store long, awkward, or bulky materials of virtually any length.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-arrow-counterclockwise"></i></div>
            <h4 class="title"><a href="">Cable Reel Rack</a></h4>
            <p class="description">Improve organization and maximize floor space, and they’re essential when workers pay out cable or wire from a consistent location.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-border-top"></i></div>
            <h4 class="title"><a href="">Mezzanine</a></h4>
            <p class="description">By building up, you can safely move operations to otherwise unused space and streamline operations in the process.</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-bezier2"></i></div>
            <h4 class="title"><a href="">Conveyor Systems</a></h4>
            <p class="description">Improve efficiency and throughput with automated tracks that move bulk material or discrete products from one area to another.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-chevron-bar-down"></i></div>
            <h4 class="title"><a href="">Netting Systems</a></h4>
            <p class="description">Helps protect people and reduce product damage in warehouses and storage areas.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-border-all"></i></div>
            <h4 class="title"><a href="">Cages</a></h4>
            <p class="description">Helps allow you to better protect inventory and equipment from theft or damage.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-arrow-up"></i></div>
            <h4 class="title"><a href="">Fencing</a></h4>
            <p class="description">Provides enhanced protection for assets, storage areas as well as the open areas on the property.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-bar-chart-steps"></i></div>
            <h4 class="title"><a href="">Pick Modules</a></h4>
            <p class="description">Racking systems that integrate different storage solutions inside multi-level work platforms that move product efficiently through a distribution facility.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-grid-fill"></i></div>
            <h4 class="title"><a href="">Modular In-plant Offices</a></h4>
            <p class="description">Prefabricated offsite and are temporary or semi-permanent in nature.
              Quick and easy installation into an existing warehouse or manufacturing facility.</p>
          </div>
        </div>

      </div>

    </div>
  </section><!-- End Services Section -->

  <!-- ======= Values Section ======= -->
  <section id="values" class="values">
    <div class="container">

      <div class="row">
        <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <div class="card" style="background-image: url(assets/img/values-1.jpg);">
            <div class="card-body">
              <h5 class="card-title"><a href="">Our Mission</a></h5>
              <p class="card-text">We build and service for your future.</p>
              <!-- <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="100">
          <div class="card" style="background-image: url(assets/img/values-2.jpg);">
            <div class="card-body">
              <h5 class="card-title"><a href="">Our Place</a></h5>
              <p class="card-text">We have completed projects all over the United States.</p>
              <!-- <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div> -->
            </div>
          </div>

        </div>
        <div class="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="200">
          <div class="card" style="background-image: url(assets/img/values-3.jpg);">
            <div class="card-body">
              <h5 class="card-title"><a href="">Our Vision</a></h5>
              <p class="card-text">To get the job done right in a cost efficient way.</p>
              <!-- <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="300">
          <div class="card" style="background-image: url(assets/img/values-4.jpg);">
            <div class="card-body">
              <h5 class="card-title"><a href="">Our Care</a></h5>
              <p class="card-text">Safety is a priority, and we have never had an on site accident.</p>
              <!-- <div class="read-more"><a href="#"><i class="bi bi-arrow-right"></i> Read More</a></div> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Values Section -->

  <!-- ======= Testimonials Section ======= -->
  <section id="testimonials" class="testimonials">
    <div class="container position-relative" data-aos="fade-up">

      <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
        <div class="swiper-wrapper">

          <div class="swiper-slide">
            <div class="testimonial-item">
              <img src="https://ui-avatars.com/api/?name=Happy+Customer" class="testimonial-img" alt="">
              <h3>Happy Customer</h3>
              <h4>Not Listed</h4>
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                The install team did a good job and was easy to work with.
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div><!-- End testimonial item -->

        </div>
        <div class="swiper-pagination"></div>
      </div>

    </div>
  </section><!-- End Testimonials Section -->



  <!-- ======= Team Section ======= -->
  <section id="team" class="team section-bg">
    <div class="container">

      <div class="section-title">
        <h2 data-aos="fade-up">Team</h2>
        <p data-aos="fade-up">The H&R Industrial Services team is made up of highly experienced multi-specialty installers, supervisors, and support staff.
          These team members are lead by a pair of owners who are dedicated to providing results that go above and beyond, and exceed customer expectations.</p>
      </div>

      <div class="row">

        <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <div class="member">
            <div class="member-img">
              <img src="https://ui-avatars.com/api/?name=Joeseph+Rappold&background=2740a3&color=ffffff&size=600" class="img-fluid" alt="">
              <div class="social">
                <!-- <a href=""><i class="bi bi-twitter"></i></a> -->
                <a href="https://www.facebook.com/HR-Industrial-Services-114157316985631" target="_blank"><i class="bi bi-facebook"></i></a>
                <a href="https://www.linkedin.com/in/hamilton-rappold-industrial-services-727738183/" target="_blank"><i class="bi bi-linkedin"></i></a>
                <!-- <a href=""><i class="bi bi-instagram"></i></a> -->
              </div>
            </div>
            <div class="member-info">
              <h4>Joseph Rappold</h4>
              <span>Owner</span>
              <p class="pt-3">With over 14 years in the industry Joseph brings a level of knowledge that our clients appreciate.
                He will walk our clients through project installation, ideas, and timelines.
                The ability to trouble shoot and correct potential issues before the arise makes him an invaluable asset.
                With a professional outlook and high skill level Joe leads our company to produce top quality results.
                When Joe is not onsite, he enjoys time with his family.</p>
            </div>
          </div>
        </div>

        <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
          <div class="member">
            <div class="member-img">
              <img src="https://ui-avatars.com/api/?name=Jeff+Hamilton&background=2740a3&color=ffffff&size=600" class="img-fluid" alt="">
              <div class="social">
                <!-- <a href=""><i class="bi bi-twitter"></i></a> -->
                <a href="https://www.facebook.com/HR-Industrial-Services-114157316985631" target="_blank"><i class="bi bi-facebook"></i></a>
                <a href="https://www.linkedin.com/in/hamilton-rappold-industrial-services-727738183/" target="_blank"><i class="bi bi-linkedin"></i></a>
                <!-- <a href=""><i class="bi bi-instagram"></i></a> -->
              </div>
            </div>
            <div class="member-info">
              <h4>Jeff Hamilton</h4>
              <span>Owner</span>
              <p class="pt-3">When you contact H&R Industrial services for your project needs you will speak with Jeff.
                In the last ten years of his career, he experienced multiple aspects of the industrial industry.
                With an honest and hardworking approach, success is defined by the production of a quality installation.
                In his free time, he enjoys time with his family.</p>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section><!-- End Team Section -->

  <!-- ======= F.A.Q Section ======= -->
  <section id="faq" class="faq section-bg">
    <div class="container">

      <div class="section-title">
        <h2 data-aos="fade-up">ISN-I Rating</h2>
        <p data-aos="fade-up">H&R Industrial Services is proud of our <b> ISN-I A+ rating</b>.</p>
      </div>

      <div class="faq-list">
        <ul>
          <li data-aos="fade-up">
            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapse"
              data-bs-target="#faq-list-1">What is an ISN-I rating? </a>
            <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
              <p>
                The International Suppliers Network is a system which logs and tracks vendors.
                Major companies such as General Motors often use the ISN to establish the "trustworthy" status of a new vendor.
                The ISN also allows companies to import a validated version of a vendor's details directly into their own procurement system.
                Companies which have an ISN Profile automatically are issued with an ISN Rating, which is a rating of a company's stability and
                ability to manage its business. This made the ISN profile a good International identifier. General ratings range from -10 to 10,
                with a default value of 1. This is based on a number of key criteria, such as financial stability, and trading history performance.
              </p>
            </div>
          </li>

          <!-- <li data-aos="fade-up" data-aos-delay="100">
            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2"
              class="collapsed">Feugiat scelerisque varius morbi enim nunc? <i
                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
              <p>
                Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id
                donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit
                ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="200">
            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3"
              class="collapsed">Dolor sit amet consectetur adipiscing elit? <i
                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
              <p>
                Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum
                integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt.
                Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300">
            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4"
              class="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i
                class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
              <p>
                Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc
                vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus
                gravida quis blandit turpis cursus in.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="400">
            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5"
              class="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem
              dolor? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
              <p>
                Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada
                nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis
                tellus in metus vulputate eu scelerisque.
              </p>
            </div>
          </li> -->

        </ul>
      </div>

    </div>
  </section><!-- End F.A.Q Section -->

  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">

      <div class="section-title">
        <h2 data-aos="fade-up">Contact</h2>
        <p data-aos="fade-up">Our experienced team is ready to answer any questions you may have. We pride ourselves in being experts in our field, and look forward to any opportunity to help you, our customer.
          Just fill out the contact form below, and an H&R Industrial Service team member will contact you at your convenience.</p>
      </div>

      <div class="row justify-content-center">

        <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
          <div class="info-box">
            <i class="bx bx-map"></i>
            <h3>Our Address</h3>
            <p><b>Shop & Billing Address:</b> 13750 Broad St. SW Pataskala Ohio 43062</p>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
          <div class="info-box" style="padding-bottom: 68px;">
            <i class="bx bx-envelope"></i>
            <h3>Email Us</h3>
            <p>contact@hamiltonrappold.com</p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
          <div class="info-box" style="padding-bottom: 44px;">
            <i class="bx bx-phone-call"></i>
            <h3>Call Us</h3>
            <p><b>Jeff:</b> 614 370-8354</p>
            <p><b>Joe:</b> 614 769-1476</p>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="300">
        <div class="col-xl-9 col-lg-12 mt-4">
          <form #contactForm="ngForm" role="form" class="php-email-form">
            <div class="row">
              <div class="col-md-6 form-group">
                <input [(ngModel)]="formModel.name" #contactName="ngModel" type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input [(ngModel)]="formModel.email" #contactEmail="ngModel" type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
              </div>
            </div>
            <div class="form-group mt-3">
              <input [(ngModel)]="formModel.subject" #contactSubject="ngModel" type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
            </div>
            <div class="form-group mt-3">
              <textarea [(ngModel)]="formModel.msg" #contactMsg="ngModel" class="form-control" name="msg" rows="5" placeholder="Message" required></textarea>
            </div>
            <!-- <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div> -->
            <div class="text-center"><button type="button" [disabled]="contactForm.invalid" (click)="addContact(formModel.name, formModel.email, formModel.subject, formModel.msg, contactForm)">Request a quote</button></div>
          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->

</main><!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer">

  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-3 col-md-6 footer-contact">
          <h3>H&R Industrial Services</h3>
          <p>
            <b>Shop & Billing Address:</b> 13750 Broad St. SW<br>
            Pataskala Ohio 43062
            United States <br>

            <strong>Phone:</strong> +1 614 370-8354<br>
            <strong>Email:</strong> contact@hamiltonrappold.com<br>
          </p>
        </div>

        <div class="col-lg-2 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#services">Services</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.termsofservicegenerator.net/live.php?token=PoJjssfiMo55DM2PbCS3idDXuviCPYNd" target="_blank">Terms of service</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.termsfeed.com/live/158ef591-dcb6-41ba-b367-2df0051d6664" target="_blank">Privacy policy</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#services">Racks</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#services">Rails</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#services">Conveyors</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#services">Netting</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#services">More</a></li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 footer-newsletter">
          <h4>Join Our Newsletter</h4>
          <p>Receive updates and H&R Industrial Services news and more, every month</p>
          <form #subscribeForm="ngForm">
            <input [(ngModel)]="formModel.email" #subscriberEmail="ngModel" type="email" name="email"><button type="button" (click)="addSubscriber(formModel.email, subscribeForm)">Subscribe</button>
          </form>
        </div>

      </div>
    </div>
  </div>

  <div class="container d-lg-flex py-4">

    <div class="me-lg-auto text-center text-lg-start">
      <div class="copyright">
        &copy; Copyright 2022 <strong><span>Hamilton Rappold Industrial Services</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        Designed with <i class="bi bi-heart"></i> by <a href="https://suremarketingsolutions.com/"> Sure Marketing Solutions</a>
      </div>
    </div>
    <div class="social-links text-center text-lg-right pt-3 pt-lg-0">
      <!-- <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> -->
      <a href="https://www.facebook.com/HR-Industrial-Services-114157316985631" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
      <a href="https://www.linkedin.com/in/hamilton-rappold-industrial-services-727738183/" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      <!-- <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a> -->
      <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a> -->
    </div>
  </div>
</footer><!-- End Footer -->

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
    class="bi bi-arrow-up-short"></i></a>

