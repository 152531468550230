// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
  apiKey: "AIzaSyCvUFkVbs9PXucq7RyMARTK9jHeTPPKkKI",
  authDomain: "hris-web.firebaseapp.com",
  databaseURL: "https://hris-web-default-rtdb.firebaseio.com",
  projectId: "hris-web",
  storageBucket: "hris-web.appspot.com",
  messagingSenderId: "1082536842025",
  appId: "1:1082536842025:web:21d47c649e8f7c9aaadd38",
  measurementId: "G-R6GH65M0ZT"
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
